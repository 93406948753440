<template>
  <span
    v-if="isShow"
  >
    <SPopover
      v-model="isOpen"
      :trigger="trigger"
      :placemen="placement"
      :title="title"
      :content="content"
      :delay-render="delayRender"
      :show-footer="showFooter"
      @button-click="handleButtonClick"
    >
      <template #buttonYes>
        {{ language.SHEIN_KEY_PC_15737 }}
      </template>
      <template #buttonNo>
        {{ language.SHEIN_KEY_PC_15738 }}
      </template>
      <template #reference>
        <div
          :styles="styles"
          class="goods-add-wish"
          @click="handleClick"
        >
          <sui_icon_save_16px_1 size="16px" />
        </div>
      </template>
    </SPopover>
  </span>
</template>

<script setup>
import { ref, computed } from 'vue'
import { SPopover } from '@shein-aidc/sui-popover'
import { sui_icon_save_16px_1 } from '@shein-aidc/icon-vue3'
import { useInjectLanguage } from '../../hooks/useLanguage'

/**
 * 商品行添加心愿组件
 */

// hooks
const language = useInjectLanguage()

// events
const emit = defineEmits(['click', 'report', 'buttonReport', 'buttonClick'])

// props
const props = defineProps({
  /**
   * 是否展示
   * @type {Boolean}
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示遮罩
   * @type {Boolean}
   */
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 触发的方式
   */
  trigger: {
    type: String,
    default: 'click',
  },
  /**
   * 出现的位置
   * @type {String}
   */
  placement: {
    type: String,
    default: 'bottom',
  },
  /**
   * 提示的title内容
   * @type {String}
   */
  title: {
    type: String,
    default: '',
  },
  /**
   * 提示的文本内容
   * @type {String}
   */
  content: {
    type: String,
    default: '',
  },
  /**
   * 延迟渲染时间
   * @type {Number}
   */
  delayRender: {
    type: Number,
    default: 300,
  },
  /**
   * 是否展示底部的操作按钮
   * @type {Boolean}
   */
  showFooter: {
    type: Boolean,
    default: true,
  },
})

// data
const isOpen = ref(false)
// computed
const styles = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
}))
// events
const handleClick = () => {
  if (isOpen.value) return
  emit('report')
  emit('click')
}
const handleButtonClick = (e) => {
  isOpen.value = false
  emit('buttonReport', e)
  emit('buttonClick', e)
}
</script>

<style lang="less" scoped>
.goods-add-wish {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: var(--mask-opacity);
  &:hover {
    background: #f6f6f6;
  }
}
</style>
