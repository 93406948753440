/**
 * FindSimilarIcon 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }) {
  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'

  return {
    isShow: data.value.aggregateProductBusiness?.cartRowViewControl?.findSimilarView?.showPositiveFindSimilar === '1',
    isShowMask: isNewcomerItem,
  }
}

