import GoodsRepickButton from './GoodsRepickButton.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsRepickButton = createHelper('GoodsRepickButton', getProps)

export {
  GoodsRepickButton,
  useGoodsRepickButton,
}
