<template>
  <div
    v-if="isShow"
    :style="styles"
    class="find-similar-icon"
    @click="handleClick"
  >
    <sui_icon_search_18px_1 size="16px" />
  </div>
</template>

<script setup>
import { watch, computed } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import { sui_icon_search_18px_1 } from '@shein-aidc/icon-vue3'

/**
 * 商品行找同款文案组件
 */

// hooks
const registerExpose = useInjectRegisterExpose()

// events
const emit = defineEmits(['click', 'report', 'expose'])

// props
const props = defineProps({
  /**
   * 是否展示
   * @type {Boolean}
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示遮罩
   * @type {Boolean}
   */
  isShowMask: {
    type: Boolean,
    default: false,
  },
})

// data
// computed
const styles = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})

// events
const handleClick = () => {
  emit('report')
  emit('click')
}

/**
 * 用户行为组件曝光
 */
const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose', props.labels)
      }
      registerExpose('FindSimilarIcon', callback)
    }
  }, {
    immediate: true,
  })
}

handleExpose()
</script>

<style lang="less" scoped>
.find-similar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: var(--mask-opacity);
  &:hover {
    background: #f6f6f6;
  }
}
</style>
