<template>
  <div
    v-if="isShow"
    :style="styles"
    class="goods-custom-content"
    @click="handleClick"
  >
    <div>{{ language.SHEIN_KEY_PC_27387 }}</div>
    <sui_icon_more_right_12px size="12px" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useInjectLanguage } from '../../hooks/useLanguage'
import { sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'

/**
 * 商品行定制商品主图组件
 */

// hooks
const language = useInjectLanguage()

// events
const emit = defineEmits(['click', 'report'])

// props
const props = defineProps({
  /**
   * 是否展示
   * @type {Boolean}
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示遮罩
   * @type {Boolean}
   */
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 按钮文案
   * @type {String}
   */
  btnText: {
    type: String,
    default: '',
  },
  /**
   * 定制信息
   * @type {Object}
   */
  data: {
    type: Object,
    default: () => ({}),
  },
  /**
   * 是否可点击
   * @type {Boolean}
   */
  isClick: {
    type: Boolean,
    default: true,
  },
})
// computed
const styles = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})
// events
const handleClick = () => {
  emit('report')
  if (props.isClick) {
    emit('click')
  }
}
</script>

<style lang="less" scoped>
.goods-custom-content {
  height: 24px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background: #f6f6f6;
  color: #222;
  padding: 0 4px 0 6px;
  gap: 4px;
  font-size: 12px;
  opacity: var(--mask-opacity);
}
</style>
