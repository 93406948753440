import FindSimilarText from './FindSimilarText.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useFindSimilarText = createHelper('FindSimilarText', getProps)

export {
  FindSimilarText,
  useFindSimilarText,
}
