import ChangeMall from './ChangeMall.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useChangeMall = createHelper('ChangeMall', getProps)

export {
  ChangeMall,
  useChangeMall,
}
