/**
 * MorePromotionTag 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ data }) {
  const morePromotion = data.value?.aggregateProductBusiness?.productTags?.find((item) => item.type === 'morePromotion')

  return {
    isShow: !!morePromotion,
    isShowMask: data.value.is_checked !== 1 && data.value.isGiftItem,
    text: morePromotion?.view?.text,
    disabledIcon: false,
    popoverTitle: morePromotion?.data?.morePromotion?.title,
    items: morePromotion?.data?.morePromotion?.items,
  }
}

export function getEvents() {
  return {}
}
