import GoodsCustomContent from './GoodsCustomContent.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsCustomContent = createHelper('GoodsCustomContent', getProps)

export {
  GoodsCustomContent,
  useGoodsCustomContent,
}
