<template>
  <div
    v-if="isShow"
    class="goods-repick-button"
    @click="handleClick"
  >
    <img
      v-if="icon"
      :src="icon"
      alt=""
      class="goods-repick-button__icon"
    />
    <span class="goods-repick-button__text">{{ text }}</span>
  </div>
</template>

<script setup>
/**
 * 商品重选按钮组件
 */

// events
const emit = defineEmits(['click'])

// props
defineProps({
  isShow: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  }
})

const handleClick = () => {
  emit('click')
}
</script>

<style lang="less" scoped>
.goods-repick-button {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  border-radius: 12px;
  border: 0.5px solid #666;
  cursor: pointer;
  .goods-repick-button__icon {
    margin-right: 4px;
    width: 12px;
    height: 12px;
  }
  .goods-repick-button__text {
    font-size: 12px;
    color: #000;
  }
}
</style>
