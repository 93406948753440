<template>
  <div
    v-if="isShow"
    :style="styles"
    :class="classes"
    @click="handleClick"
  >
    {{ text }}
  </div>
</template>

<script setup>
import { computed } from 'vue'

/**
 * 商品行找同款文案组件
 */

// events
const emit = defineEmits(['click', 'report', 'expose'])

// props
const props = defineProps({
  /**
   * 是否展示
   * @type {Boolean}
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示 遮罩
   * @type {Boolean}
   */
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否可点击
   * @type {Boolean}
   */
  isClick: {
    type: Boolean,
    default: false,
  },
  /**
   * 文字
   */
  text: {
    type: String,
    default: '',
  }
})

// data
// computed
const styles = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})
const classes = computed(() => {
  return {
    'find-similar-text': true,
    'find-similar-text_disabled': !props.isClick,
  }
})

// events
const handleClick = () => {
  emit('report')
  if (!props.isClick) {
    emit('click')
  }
}
</script>

<style lang="less" scoped>
.find-similar-text {
  display: inline-block;
  padding: 4.5px 10px;
  color: @sui_color_gray_dark1;
  font-weight: bold;
  cursor: pointer;
  background: #F2F2F2;
  border-radius: 14px;
  font-size: 13px;
  border: 1px solid #F2F2F2;
  text-decoration: none;
  opacity: var(--mask-opacity);
  &:hover:not(.find-similar-text_disabled) {
    border: 1px solid #222222;
  }
  &_disabled {
    opacity: .3;
    cursor: default;
    cursor: not-allowed
  }
}
</style>
