/**
 * 删除按钮组件 GoodsDeleteButton
 * GoodsDeleteButton 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }) {
  const additionGoodsView = data.value.aggregateProductBusiness?.cartRowViewControl?.additionGoodsView
  return {
    isShow: !!additionGoodsView,
    icon: additionGoodsView?.rePickIcon,
    text: additionGoodsView?.rePickBtnText
  }
}
