<template>
  <div
    v-if="isShow"
    :style="styles"
    class="change-mall__wrap"
    @click="handleClick"
  >
    <span
      class="change-mall__text"
    >
      {{ btnText }}
    </span>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'

/**
 * 商品行切换MALL组件
 */

// hooks
const registerExpose = useInjectRegisterExpose()

// events
const emit = defineEmits(['click', 'report', 'expose'])

// props
const props = defineProps({
  /**
   * 是否展示
   * @type {String}
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示遮罩
   * @type {Boolean}
   */
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 按钮文案
   * @type {String}
   */
  btnText: {
    type: String,
    default: '',
  },
})

// data
// computed
const styles = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})
// events
const handleClick = () => {
  emit('report')
  if (props.isClick) {
    emit('click')
  }
}
const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose')
      }
      registerExpose('ChangeMall', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less" scoped>
.change-mall {
  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    padding: 4px 10px;
    border: 0.5px solid #000;
    background-color: #FFFFFF;
    border-radius: 16px;
    cursor: pointer;
    opacity: var(--mask-opacity);
  }
  &__text {
    font-size: 13px;
    font-weight: 700;
    color: #222;
  }
}
</style>
